import { Box } from "@material-ui/core"
import { FunctionComponent } from "react"

export interface SpacerProps {
  vertical?: number | Record<string, number>
  horizontal?: number | Record<string, number>
}

export const Spacer: FunctionComponent<SpacerProps> = ({
  vertical = 0,
  horizontal = 0,
}) => <Box pt={vertical} pl={horizontal} />
