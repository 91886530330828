import { Box, Grid, Typography } from "@material-ui/core"
import { GetStaticProps } from "next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { NextSeo } from "next-seo"
import React, { FunctionComponent } from "react"
import { useLoginFormElements } from "../components/authentication/login/useLoginFormElements"
import { FlatLink } from "../components/FlatLink"
import { Spacer } from "../components/Spacer"
import { SplitDivider } from "../components/SplitDivider"
import { loginMethodsConfig } from "../config/firebase"
import { LoginLayout } from "../layouts/login"
import { i18n } from "../next-i18next.config"
import { RedirectLink } from "../utils/routing/RedirectLink"
import { useWebsiteName } from "../utils/useWebsiteName"

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      ...(await serverSideTranslations(i18n.defaultLocale, [])),
    },
  }
}

const LoginPage: FunctionComponent = () => {
  const {
    LoginForm,
    EmailField,
    PasswordField,
    LoginButton,
    LoginError,
    FacebookLoginButton,
    GoogleLoginButton,
    SSOLoginButton,
  } = useLoginFormElements()
  const websiteName = useWebsiteName()

  return (
    <LoginLayout>
      <NextSeo
        title={`${websiteName} - Login`}
        description={`Log in to ${websiteName} to watch the event.`}
      />

      {loginMethodsConfig.email && (
        <Box position="absolute" padding={2} right="0" top="0">
          <Typography paragraph>
            Don't have an account?{" "}
            <RedirectLink href="/register" keepRedirect={true} passHref>
              <FlatLink>Register</FlatLink>
            </RedirectLink>
          </Typography>
        </Box>
      )}

      <Box width={{ xs: "100%", lg: "50%" }} p={4} pt={{ xs: 8, sm: 4 }}>
        <LoginForm>
          <Grid container spacing={4} direction="column">
            <Grid item>
              <Typography variant="h5">Sign in to {websiteName}</Typography>
            </Grid>

            <LoginError
              wrapper={({ children }) => <Grid item>{children}</Grid>}
            />

            {loginMethodsConfig.email && (
              <>
                <Grid item>
                  <EmailField />
                </Grid>

                <Grid item>
                  <PasswordField />
                </Grid>

                <Grid item>
                  <LoginButton />

                  <Spacer vertical={2} />

                  <Box textAlign="center">
                    <RedirectLink href="/forgot-password" passHref>
                      <FlatLink>Forgot password?</FlatLink>
                    </RedirectLink>
                  </Box>
                </Grid>
              </>
            )}

            {loginMethodsConfig.email && loginMethodsConfig.thirdParty() && (
              <Grid item>
                <SplitDivider>Or</SplitDivider>
              </Grid>
            )}

            {loginMethodsConfig.facebook && (
              <Grid item>
                <FacebookLoginButton />
              </Grid>
            )}

            {loginMethodsConfig.google && (
              <Grid item>
                <GoogleLoginButton />
              </Grid>
            )}

            {loginMethodsConfig.sso && (
              <Grid item>
                <SSOLoginButton />
              </Grid>
            )}
          </Grid>
        </LoginForm>
      </Box>
    </LoginLayout>
  )
}

export default LoginPage
